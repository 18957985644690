<template>
    <div class="customer-page">
        <page-title :title="'客户管理'">客户信息维护</page-title>
        <div class="user-sel">
            <el-input size="small" placeholder="请输入客户姓名检索" v-model="selCon.cusName"
                style="width:280px;margin-right:10px;" clearable></el-input>
            <el-button icon="el-icon-search" type="primary" size="small" @click="userSel">查询</el-button>
        </div>
        <el-button icon="el-icon-plus" type="success" size="small" @click="addDialog=true">新增客户</el-button>
        <div class="user-table">
            <el-table :data="tableData" size="mini" border style="width: 100%">
                <el-table-column type="index" label="序号" width="50" align="center">
                </el-table-column>
                <el-table-column prop="cusName" label="姓名" width="120" align="center">
                </el-table-column>
                <el-table-column prop="cusTel" label="联系方式" width="120" align="center">
                </el-table-column>
                <el-table-column prop="cusSex" label="性别" width="60" align="center">
                </el-table-column>
                <el-table-column prop="idnum" label="证件号" width="180" align="center">
                </el-table-column>
                <el-table-column prop="cusAddr" label="客户地址" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="创建日期" width="160" align="center">
                </el-table-column>
                <el-table-column prop="remark" label="用户备注" show-overflow-tooltip header-align="center">
                </el-table-column>
                <el-table-column prop="caseNum" label="关联案件数" width="100" align="center">
                </el-table-column>
                <el-table-column label="操作" header-align="center" min-width="260" width="280" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="editUser(scope.row)">查看详情</el-button>
                        <el-button size="mini" type="warning" @click="editUser(scope.row)">修改</el-button>
                        <el-button size="mini" type="danger" @click="deleteCus(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination small background layout="prev, pager, next" :total="page.total" @current-change="handleCurrentChange" :current-page.sync="page.current"
                style="margin-top:15px;text-align: right;">
            </el-pagination>
        </div>
        <el-dialog title="新增客户" :visible.sync="addDialog" custom-class="my-dialog-style" @close="resetForm">
            <el-form ref="form" :model="form" size="small" label-width="100px" label-position="right">
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item label="客户姓名：" prop="cusName">
                            <el-input v-model="form.cusName" placeholder="请输入客户姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系方式:" prop="cusTel">
                            <el-input v-model="form.cusTel" placeholder="请输入联系方式"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item label="证件号码：" prop="idnum">
                            <el-input v-model="form.idnum" placeholder="请输入证件号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别:" prop="cusSex">
                            <el-radio-group v-model="form.cusSex">
                                <el-radio label="男">男</el-radio>
                                <el-radio label="女">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-form-item label="客户地址：" prop="cusAddr">
                            <el-input v-model="form.cusAddr" placeholder="请输入用户地址"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注：" prop="remark">
                            <el-input v-model="form.remark" placeholder="备注说明"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="证件图片：" prop="idnumImg">
                            <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/"
                                list-type="picture-card" multiple>
                                <i class="el-icon-plus"></i>
                                <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addDialog = false" size="small">取 消</el-button>
                <el-button type="primary" @click="saveOrEdit" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "Customer",
    data() {
        return {
            addDialog: false,
            form: {
                cusSex: "男"
            },
            tableData: [],
            selCon: {
                cusName: ""
            },
            page:{
                current:1,
                pageSize:10,
                total:0
            },
        }
    },
    created() {
        this.initPage();
    },
    methods: {
        /**初始加载页面数据 */
        initPage() {
            const that = this;
            that.request.get("cusinfo/page",that.page).then(res => {
                if(res.code === 200){
                    that.tableData = res.data.records;
                    that.page.total = res.data.total;
                }
            });
        },
        /**当前页变化 */
        handleCurrentChange() {
            this.initPage();
        },
        /**用户检索 */
        userSel() {
            const that = this;
            that.page = Object.assign(that.page,that.selCon);
            console.log(that.page);
            that.initPage();
        },
        /**保存或者修改 */
        saveOrEdit() {
            console.log(this.form);
            const that = this;
            that.request.post("cusinfo/saveOrEdit", that.form).then(res => {
                if (res.code === 200) {
                    that.addDialog = false;
                    that.initPage();
                    that.$refs['form'].resetFields();
                }
            });
        },
        /**修改弹框赋值操作 */
        editUser(row) {
            this.form = JSON.parse(JSON.stringify(row));
            this.addDialog = true;
        },
        /**删除操作 */
        deleteCus(id) {
            const that = this;
            that.$confirm('此操作将永久删除该客户及关联数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.request.get("cusinfo/delete", { cusId: id }).then(res => {
                    if (res.code === 200) {
                        that.initPage();
                    }
                });
            }).catch({});
        },
        /**重置表单 */
        resetForm() {
            this.$refs['form'].resetFields();
            this.form.id = null;
        }
    }
}
</script>
<style lang="less" scoped>
.customer-page {
    .user-sel {
        display: flex;
        margin-bottom: 15px;
    }

    .user-table {
        margin-top: 15px;
    }
}
</style>